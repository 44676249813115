import { render, staticRenderFns } from "./pointsList.vue?vue&type=template&id=bca1d828&scoped=true"
import script from "./pointsList.vue?vue&type=script&lang=js"
export * from "./pointsList.vue?vue&type=script&lang=js"
import style0 from "./pointsList.vue?vue&type=style&index=0&id=bca1d828&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca1d828",
  null
  
)

export default component.exports